import React, { Component } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import logoBlue from '../assets/images/blue-logo.png';

export default class AdminLogin extends Component {

  apiEndPoint = process.env.GATSBY_API_URL;

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    }
  }

  handleInputChanges = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    axios.post(`${this.apiEndPoint}/auth/local`, {
      identifier: this.state.username,
      password: this.state.password
    }).then(
      (response) => {
        const userData = response.data;
        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/admin-panel');
      }
    ).catch(
      (err) => {
        console.error(err);
      }
    ).finally(
      () => {

      }
    )
  }

  render() {
    const { username, password } = this.state;
    return (
      <div className="custom-admin-wrapper admin-login-wrapper">
        <div className="login-box">
        <img src={logoBlue} className="img-fluid" alt="" />
          <form onSubmit={this.handleSubmit}>
            <div className="admin-login-input-wrapper">
              <label>Username</label>
              <input
                type="text"
                name="username"
                value={username}
                onChange={this.handleInputChanges}
                required
              />
              <i className="login-input-icon fas fa-user"></i>
            </div>
            <div className="admin-login-input-wrapper">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={this.handleInputChanges}
                required
              />
              <i className="login-input-icon fas fa-lock"></i>
            </div>
            <button type="submit" className="admin-login-button">Log in</button>
          </form>
        </div>
      </div>
    )
  }

}